/*
!! _extend.scss
*/
//# =================================================================
//# 変数定義
//# =================================================================
//# 共通boder-line
$line: solid 1px map-get($theme_colors, "org");
//# 共通transition
$transition: all 0.5s ease;
//# =================================================================
//# 抽象クラス定義
//# =================================================================
// %extend_cmn_01 : delighter.jsでタグに付与されるクラスに関するcss設定
%extend_cmn_01 {
  &.delighter {
    &::before {
      transition: $transition;
      opacity: 0;
    }
    &.started {
      &::before {
        opacity: 0.1;
        @include mediaQuery() {
          @include mediaQuery(landscape) {
            opacity: 1;
          }
        }
        @include mediaQuery(large) {
          opacity: 1;
        }
      }
      &.ended {
        &::before {
          opacity: 0;
        }
        .detail {
          opacity: 0;
        }
      }
      .detail {
        opacity: 1;
      }
    }
    .detail {
      transition: $transition;
      opacity: 0;
    }
  }
}
// %extend_cmn_02 : sectionタグに関するcss設定
%extend_cmn_02 {
  &::before {
    @include fixed(
      $size: (
        100vh,
        100%,
      )
    );
    background: {
      size: contain;
      position: center;
      repeat: no-repeat;
    }
    z-index: -1;
    @include mediaQuery() {
      @include mediaQuery(landscape) {
        @include fixed(
          $positions: (
            null,
            null,
            null,
            50%,
          ),
          $size: (
            100vh,
            50%,
          )
        );
      }
    }
    @include mediaQuery(large) {
      @include fixed(
        $positions: (
          null,
          null,
          null,
          50%,
        ),
        $size: (
          100vh,
          50%,
        )
      );
    }
  }
}
// %extend_cmn_03 : sectionタグ以下のarticleタグに関するcss設定
%extend_cmn_03 {
  .content {
    @include absolute(
      $positions: (
        0,
        null,
        null,
        0,
      ),
      $size: (
        100vh,
        100%,
      )
    );
    @include mediaQuery() {
      @include mediaQuery(landscape) {
        @include size(null, 50%!important);
      }
    }
    @include mediaQuery(large) {
      @include size(null, 50%!important);
    }
  }
}
