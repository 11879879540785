/*
!! _extend.scss
*/
section.delighter::before {
  transition: all 0.5s ease;
  opacity: 0;
}

section.delighter.started::before {
  opacity: 0.1;
}

@media screen and (min-width: 768px) and (orientation: landscape) {
  section.delighter.started::before {
    opacity: 1;
  }
}

@media screen and (min-width: 1024px) {
  section.delighter.started::before {
    opacity: 1;
  }
}

section.delighter.started.ended::before {
  opacity: 0;
}

section.delighter.started.ended .detail {
  opacity: 0;
}

section.delighter.started .detail {
  opacity: 1;
}

section.delighter .detail {
  transition: all 0.5s ease;
  opacity: 0;
}

section::before {
  position: fixed;
  height: 100vh;
  width: 100%;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  z-index: -1;
}

@media screen and (min-width: 768px) and (orientation: landscape) {
  section::before {
    position: fixed;
    left: 50%;
    height: 100vh;
    width: 50%;
  }
}

@media screen and (min-width: 1024px) {
  section::before {
    position: fixed;
    left: 50%;
    height: 100vh;
    width: 50%;
  }
}

section .content {
  position: absolute;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100%;
}

@media screen and (min-width: 768px) and (orientation: landscape) {
  section .content {
    width: 50% !important;
  }
}

@media screen and (min-width: 1024px) {
  section .content {
    width: 50% !important;
  }
}

section#company table, section#contact table {
  white-space: nowrap;
  border-collapse: separate;
}

section#company table tr > th {
  border-bottom: solid 1px #f04400;
  border-right: none;
}

@media screen and (min-width: 768px) {
  section#company table tr > th {
    text-align: center;
    padding: calc(0.5rem + 0.25vw) 10px;
    border-bottom: none;
    border-right: solid 1px #f04400;
  }
}

@media screen and (orientation: landscape) {
  section#company table tr > th {
    text-align: center;
    padding: calc(0.5rem + 0.25vw) 10px;
    border-bottom: none;
    border-right: solid 1px #f04400;
  }
}

section#company table tr > td {
  padding-right: 10px;
}

@media screen and (min-width: 768px) {
  section#company table tr > td {
    padding: calc(0.5rem + 0.25vw) 10px;
  }
}

@media screen and (orientation: landscape) {
  section#company table tr > td {
    padding: calc(0.5rem + 0.25vw) 10px;
  }
}

section#company table tr:nth-child(8) span {
  font-size: 0.8em;
}

@media screen and (max-width: 767px) and (orientation: portrait) {
  section#company table tr:nth-child(n + 3):nth-child(-n + 5) > th {
    display: none;
  }
  section#company table tr:nth-child(3) > td:nth-of-type(1) {
    display: none;
  }
  section#company table tr:nth-child(8) > td {
    display: inline-block;
  }
  section#company table tr:nth-child(8) > td:nth-of-type(1) {
    padding-right: 2em;
  }
}

@media screen and (min-width: 768px) {
  section#company table tr:nth-child(n + 3):nth-child(-n + 5) > td:nth-of-type(1) {
    text-align: right;
  }
}

@media screen and (orientation: landscape) {
  section#company table tr:nth-child(n + 3):nth-child(-n + 5) > td:nth-of-type(1) {
    text-align: right;
  }
}

section#contact table tr * {
  vertical-align: middle;
}

section#contact table tr td > img {
  height: 2em;
  width: auto;
}

section .content h1.tlt {
  opacity: 0;
  transition: all 0.5s ease;
}

section .content h1 span.word2,
section .content h1 span.word3 {
  font-size: 0.5em;
  font-weight: normal;
}

@media screen and (max-width: 767px) and (orientation: portrait) {
  section#company table tr, section#company table tr > th {
    display: block;
  }
}

section#concept::before {
  background-image: url("../../common/images/logo_001.svg");
}

section#service::before {
  background-image: url("../../common/images/logo_002.svg");
}

section#service article:nth-of-type(1) {
  width: 60%;
}

section#service article:nth-of-type(1) ul li:nth-last-child(1) > * {
  font-size: 0.8em;
}

section#company::before {
  background-image: url("../../common/images/logo_001.svg");
}

section#company table {
  border-spacing: 0;
}

section#contact::before {
  background-image: url("../../common/images/logo_003.svg");
}

section#contact table {
  border-spacing: 10px;
}
