//*
//* type01.top.css
//*

//# =================================================================
//# import scss
//# =================================================================
@import "../../common/scss/config/const";
@import "../../common/scss/config/mixin";
@import "../../common/scss/config/extend";
//# =================================================================
//# 変数定義
//# =================================================================
$nameList: (concept, service, company, contact);
$space: (
  company: 0,
  contact: 10px,
);
$pad: append(map-get($theme_size, "padding"), 10px);
$fontSize_span: 0.5em, 0.8em;
//# =================================================================
//# 抽象クラス定義
//# =================================================================
// %extend_top_01  : tableタグに関するcss設定
%extend_top_01 {
  table {
    white-space: nowrap;
    border-collapse: separate;
  }
}
// %extend_top_02  : tableタグ以下（th・td）に関するcss設定(section#company用)
%extend_top_02 {
  table {
    tr {
      @extend %extend_parts_01;
      > th {
        @extend %extend_parts_01;
        border : {
          bottom: $line;
          right: none;
        }
        @each $breake in (medium, landscape) {
          @include mediaQuery($breake) {
            text-align: center;
            padding: $pad;
            border : {
              bottom: none;
              right: $line;
            }
          }
        }
      }
      > td {
        padding-right: 10px;
        @each $breake in (medium, landscape) {
          @include mediaQuery($breake) {
            padding: $pad;
          }
        }
      }
      &:nth-child(8) {
        span {
          font-size: nth($fontSize_span, 2);
        }
      }
      @include mediaQuery(medium, max) {
        @include mediaQuery(portrait) {
          &:nth-child(n + 3):nth-child(-n + 5) {
            > th {
              display: none;
            }
          }
          &:nth-child(3) {
            > td:nth-of-type(1) {
              display: none;
            }
          }
          &:nth-child(8) {
            > td {
              display: inline-block;
              &:nth-of-type(1) {
                padding-right: 2em;
              }
            }
          }
        }
      }
      &:nth-child(n + 3):nth-child(-n + 5) {
        @each $breake in (medium, landscape) {
          @include mediaQuery($breake) {
            > td:nth-of-type(1) {
              text-align: right;
            }
          }
        }
      }
    }
  }
}
// %extend_top_03  : tableタグ以下（th・td）に関するcss設定(section#company用)
%extend_top_03 {
  table {
    tr {
      * {
        vertical-align: middle;
      }
      td > img {
        @include size(2em, auto);
      }
    }
  }
}
// %extend_top_04  : article以下に含まれるタグに関するcss
%extend_top_04 {
  .content {
    h1 {
      &.tlt {
        opacity: 0;
        transition: $transition;
      }
      span.word2,
      span.word3 {
        font: {
          size: nth($fontSize_span, 1);
          weight: normal;
        }
      }
    }
  }
}
// %extend_parts_01 : %extend_top_03に継承用
%extend_parts_01 {
  @include mediaQuery(medium, max) {
    @include mediaQuery(portrait) {
      display: block;
    }
  }
}
//# =================================================================
//#
//# =================================================================
section {
  @extend %extend_cmn_01;
  @extend %extend_cmn_02;
  @extend %extend_cmn_03;
  @extend %extend_top_04;
  @each $navName in $nameList {
    &##{$navName} {
      &::before {
        background-image: url(map-get($img_urls, $navName));
      }
      @if ($navName == company) {
        table {
          border-spacing: map-get($space, $navName);
        }
        @extend %extend_top_01;
        @extend %extend_top_02;
      } @else if ($navName == service) {
        article:nth-of-type(1) {
          width: 60%;
          ul li:nth-last-child(1) > * {
            font: {
              size: nth($fontSize_span, 2);
            }
          }
        }
      } @else if ($navName == contact) {
        table {
          border-spacing: map-get($space, $navName);
        }
        @extend %extend_top_01;
        @extend %extend_top_03;
      }
    }
  }
}
